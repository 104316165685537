\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="관리번호"
            name="managerNo"
            v-model="searchParam.managerNo">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="지역(공정)"
            name="areaName"
            v-model="searchParam.areaName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="설치장소"
            name="location"
            v-model="searchParam.location">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관련공정 -->
          <c-process
            label="LBL0001705"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 관리부서 -->
          <c-dept
            type="search"
            label="LBL0001721"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 세척세안시설 목록 -->
        <c-table
          ref="localven"
          title="LBL0010550"
          tableId="localven"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="psiFaceWashingId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 등록 -->
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="equipmentPopup" />
              <!-- 검색 -->
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'face-washing',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'managerNo',
            field: 'managerNo',
            // 관리번호
            label: 'LBL0010551',
            style: 'width:120px',
            type: 'link',
            align: 'center',
            sortable: true,
          },
          {
            name: 'areaName',
            field: 'areaName',
            // 지역(공정)
            label: 'LBL0010552',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'location',
            field: 'location',
            // 설치장소
            label: 'LBL0010553',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'warmMethod',
            field: 'warmMethod',
            // 보온방법
            label: 'LBL0010554',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'checkCycle',
            field: 'checkCycle',
            // 점검주기
            label: 'LBL0010473',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'remark',
            field: 'remark',
            // 비고
            label: 'LBLREMARK',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        managerNo: '',
        areaName: '',
        location: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.psi.lbe.wash.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.equipmentPopup(row);
    },
    equipmentPopup(result) {
      this.popupOptions.target = () => import(`${"./washingFacilityPlanInfoDetail.vue"}`);
      this.popupOptions.title = 'LBL0010555'; // 세척세안시설 상세
      this.popupOptions.param = {
        psiFaceWashingId: result ? result.psiFaceWashingId : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
